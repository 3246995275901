import React from 'react'
import { x } from '@xstyled/styled-components'
// import Link from './../components/ui/Link'

const CommercialNav = (props) => (
  <x.div w='100%' backgroundColor='black' visibility={{ sm: 'hidden', lg: 'visible' }} display={{ sm: 'none', lg: 'flex' }} justifyContent='center' px={20}>
    <x.div w='100%' maxWidth='1220px' display='flex' alignItems='center'>
      <x.div w='80%' textTransform='uppercase' fontSize='meta' my={6} display='flex' alignItems='center'>
        <x.a opacity={{ hover: 0.8 }} target='_blank' href='https://www.loot.co.za/welcome?utm_source=iollink&utm_medium=textlink&utm_campaign=iolnavbarshopatlootlink' mr={15} text='commercial-nav'>Shop @ Loot</x.a>
        {/* <x.a opacity={{ hover: 0.8 }} target='_blank' href='/personal-finance' mr={15} text='commercial-nav'>Personal Finance</x.a>
        <x.a opacity={{ hover: 0.8 }} target='_blank' href='https://www.isolezwe.co.za/' mr={15} text='commercial-nav'>Isolezwe</x.a>
        <x.a opacity={{ hover: 0.8 }} target='_blank' href='https://www.dailyvoice.co.za/' mr={15} text='commercial-nav'>Daily Voice</x.a>
        <x.a opacity={{ hover: 0.8 }} target='_blank' href='https://www.iolproperty.co.za/' mr={15} text='commercial-nav'>IOL Property</x.a>
        <x.a opacity={{ hover: 0.8 }} target='_blank' href='https://issuu.com/independentnewspapers' mr={15} text='commercial-nav'>Digi Mags</x.a> */}
        {/* <Link opacity={{ hover: 0.8 }} mr={15} fontSize='0.75rem' color='white' to={'/digiserts'}>Digiserts</Link> */}
      </x.div>
    </x.div>
  </x.div>
)

export default CommercialNav
